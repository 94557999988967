import { createRouter, createWebHistory } from "vue-router";
import { auth } from '../auth/auth';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/movingboard',
    name: 'Board',
    component: () => import('../views/BoardView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/boardmanager/:id?',
    name: 'Board Manager',
    component: () => import('../views/BoardManager.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/board/:id',
    name: 'Gift Board',
    component: () => import('../views/GiftBoardView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
    },
    meta: { requiresAuth: false }
  },  
  {
    path: '/onboard',
    name: 'Onboard Donee',
    component: () => import('../views/OnboardView.vue'),
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.isAuthenticated()) {
      auth.login();
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
