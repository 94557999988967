// src/auth/auth.js
import { cognitoConfig } from './cognitoConfig';

export const auth = {
  async generateCodeChallenge(codeVerifier) {
    console.log('Generating code challenge');
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  },

  generateCodeVerifier() {
    console.log('Generating code verifier');
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...array))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  },

  async login() {
    console.log('Logging in');
    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);
    
    // Store the code verifier for later use
    localStorage.setItem('codeVerifier', codeVerifier);
    
    const queryParams = new URLSearchParams({
      client_id: cognitoConfig.ClientId,
      response_type: 'code',
      scope: cognitoConfig.Scopes.join(' '),
      redirect_uri: cognitoConfig.RedirectUri,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      auth_flow: 'USER_SRP_AUTH'
    });
    
    window.location.href = `https://${cognitoConfig.Domain}/login?${queryParams}`;
  },
  
  logout() {
    console.log('Logging out');
    localStorage.removeItem('authCode');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('codeVerifier');
    
    const queryParams = new URLSearchParams({
      client_id: cognitoConfig.ClientId,
      logout_uri: cognitoConfig.RedirectUri
    });
    
    window.location.href = `https://${cognitoConfig.Domain}/logout?${queryParams}`;
  },

  async handleRedirect() {
    console.log('Handling redirect');
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const codeVerifier = localStorage.getItem('codeVerifier');
    
    if (code && codeVerifier) {
        console.log('Code and code verifier found');
      localStorage.setItem('authCode', code);
      
      try {
        console.log('Exchanging code for token');
        const tokenEndpoint = `https://${cognitoConfig.Domain}/oauth2/token`;
        const response = await fetch(tokenEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: cognitoConfig.ClientId,
            redirect_uri: cognitoConfig.RedirectUri,
            code: code,
            code_verifier: codeVerifier
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Token exchange error:', errorData);
          throw new Error(`Failed to exchange code for token: ${errorData.error}`);
        }
        console.log('Successfully exchanged code for token');

        const tokens = await response.json();
        
       // Decode both tokens to get user information
       const accessPayload = JSON.parse(atob(tokens.access_token.split('.')[1]));
       const idPayload = tokens.id_token ? JSON.parse(atob(tokens.id_token.split('.')[1])) : null;
       const cognitoUserId = accessPayload.sub;  // 'sub' claim contains the Cognito user ID
       const userEmail = idPayload?.email || accessPayload.email; // Try to get email from either token
  
        
        // Check if user exists in your database
        try {
          const userResponse = await fetch(
            'https://6zbjq2u1n9.execute-api.us-east-1.amazonaws.com/default/private/user',
            {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${tokens.access_token}`,
                'Content-Type': 'application/json'
              },
              // Add credentials if using allowCredentials: true
            }
          );
          const userData = await userResponse.json();
          
        console.log('User data:', userData);
          if (Array.isArray(userData) && userData.length === 0) {
            // User doesn't exist, create new user record
            console.log('User doesnt exist - Creating user record');
            const body = JSON.stringify({
              cognitoId: cognitoUserId,
              email: userEmail,
              this: 'that'
            })
            console.log(body);
            const createResponse = await fetch('https://6zbjq2u1n9.execute-api.us-east-1.amazonaws.com/default/private/createUser', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${tokens.access_token}`,
                'Content-Type': 'application/json',
              },
              body: body,
            });
            


            if (!createResponse.ok) {
              console.error('Failed to create user record');
            } else {
              console.log('User record created');}
              localStorage.setItem('user', JSON.stringify(createResponse));
          } else {
            console.log('User exists - Updating user record in local storage');
            localStorage.setItem('user', JSON.stringify(userData));
          }
        } catch (error) {
          console.error('Error checking/creating user record:', error);
        }
        

        // store user object in local storage
        //localStorage.setItem('user', JSON.stringify(userData));

        // Clear code verifier as it's no longer needed
        localStorage.removeItem('codeVerifier');
        
        // Store tokens and set expiration
        localStorage.setItem('refreshToken', tokens.refresh_token);
        localStorage.setItem('accessToken', tokens.access_token);
        
        // Set token expiration (default Cognito access token expires in 1 hour)
        const expirationTime = new Date().getTime() + (tokens.expires_in * 1000);
        localStorage.setItem('tokenExpiration', expirationTime);
        
        return tokens.access_token;
      } catch (error) {
        console.error('Error exchanging code for token:', error);
        return null;
      }
    }
    return null;
  },

  isAuthenticated() {
    return !!localStorage.getItem('accessToken');
  },
  getUser() { 
    return JSON.parse(localStorage.getItem('user'));
  },

  async refreshAccessToken() {
    console.log('Refreshing token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return null;
    }

    try {
      const tokenEndpoint = `https://${cognitoConfig.Domain}/oauth2/token`;
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: cognitoConfig.ClientId,
          refresh_token: refreshToken
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Token refresh error:', errorData);
        throw new Error(`Failed to refresh token: ${errorData.error}`);
      }

      const tokens = await response.json();
      console.log('Successfully refreshed token');

      // Update access token and its expiration
      localStorage.setItem('accessToken', tokens.access_token);
      const expirationTime = new Date().getTime() + (tokens.expires_in * 1000);
      localStorage.setItem('tokenExpiration', expirationTime);

      return tokens.access_token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      // If refresh fails, user needs to login again
      this.logout();
      return null;
    }
  },

  async getToken() {
    console.log('Getting token from storage');
    const accessToken = localStorage.getItem('accessToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    
    if (!accessToken || !tokenExpiration) {
        console.log('No token found or token expired');
      return null;
    }

    // Check if token is expired or will expire in the next 5 minutes
    const isExpired = new Date().getTime() > (parseInt(tokenExpiration) - 5 * 60 * 1000);
    
    if (isExpired) {
      // Token is expired or will expire soon, try to refresh it
      console.log('Token expired or will expire soon, refreshing');
      return await this.refreshAccessToken();
    }
    
    return accessToken;
  },
  async apiQuery (method, handler, params) {
    console.log('Making API query');
    const token = await this.getToken();
    if (!token) {
      console.log('No token found');
      return null;
    }
    
    try {
      let url = `https://6zbjq2u1n9.execute-api.us-east-1.amazonaws.com/default/${handler}`;
      let options = {
        method: method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      if (method === 'GET') {
        // Add params to URL
        url += '?' + new URLSearchParams(params).toString();
      } else if (method === 'POST') {
        options.body = JSON.stringify(params);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API query error:', errorData);
        throw new Error(`API query failed: ${errorData.error}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error making API query:', error);
      return null;
    }
  }
};

