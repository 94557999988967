<template>
  <!-- Desktop Navigation -->
  <nav class="hidden md:block bg-white shadow-sm">
    <div class="max-w-6xl mx-auto px-4 h-16">
      <div class="flex items-center justify-between h-full">
        <div class="flex space-x-8">
          <router-link 
            to="/" 
            class="flex items-center px-3 py-2 text-gray-700 hover:text-green-600 transition-colors"
            :class="{ 'text-green-600 font-semibold': $route.path === '/' }"
          >
            <span class="material-icons-outlined mr-1">home</span>
            Home
          </router-link>
          
          <router-link 
            to="/about" 
            class="flex items-center px-3 py-2 text-gray-700 hover:text-green-600 transition-colors"
            :class="{ 'text-green-600 font-semibold': $route.path === '/about' }"
          >
            <span class="material-icons-outlined mr-1">info</span>
            About
          </router-link>
          
          <router-link 
            to="/board/1" 
            class="flex items-center px-3 py-2 text-gray-700 hover:text-green-600 transition-colors"
            :class="{ 'text-green-600 font-semibold': $route.path.startsWith('/board') }"
          >
            <span class="material-icons-outlined mr-1">card_giftcard</span>
            Gift Board
          </router-link>
        </div>
        
        <div class="flex space-x-4">
          <router-link 
            to="/dashboard" 
            class="flex items-center px-3 py-2 text-gray-700 hover:text-green-600 transition-colors"
            :class="{ 'text-green-600 font-semibold': $route.path === '/dashboard' }"
          >
            <span class="material-icons-outlined mr-1">dashboard</span>
            Dashboard
          </router-link>
          
          <router-link 
            to="/boardmanager" 
            class="flex items-center px-3 py-2 text-gray-700 hover:text-green-600 transition-colors"
            :class="{ 'text-green-600 font-semibold': $route.path === '/boardmanager' }"
          >
            <span class="material-icons-outlined mr-1">settings</span>
            Manager
          </router-link>
        </div>
      </div>
    </div>
  </nav>

  <!-- Main Content -->
  <main class="min-h-screen pb-16 md:pb-0 pt-4">
    <router-view />
  </main>

  <!-- Mobile Bottom Navigation -->
  <nav class="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-100">
    <div class="grid grid-cols-5 h-16">
      <router-link 
        to="/" 
        class="flex flex-col items-center justify-center text-gray-700 hover:text-green-600 transition-colors"
        :class="{ 'text-green-600': $route.path === '/' }"
      >
        <span class="material-icons-outlined text-xl mb-1">home</span>
        <span class="text-xs">Home</span>
      </router-link>

      <router-link 
        to="/about" 
        class="flex flex-col items-center justify-center text-gray-700 hover:text-green-600 transition-colors"
        :class="{ 'text-green-600': $route.path === '/about' }"
      >
        <span class="material-icons-outlined text-xl mb-1">info</span>
        <span class="text-xs">About</span>
      </router-link>

      <router-link 
        to="/board/1" 
        class="flex flex-col items-center justify-center text-gray-700 hover:text-green-600 transition-colors"
        :class="{ 'text-green-600': $route.path.startsWith('/board') }"
      >
        <span class="material-icons-outlined text-xl mb-1">card_giftcard</span>
        <span class="text-xs">Board</span>
      </router-link>

      <router-link 
        to="/dashboard" 
        class="flex flex-col items-center justify-center text-gray-700 hover:text-green-600 transition-colors"
        :class="{ 'text-green-600': $route.path === '/dashboard' }"
      >
        <span class="material-icons-outlined text-xl mb-1">dashboard</span>
        <span class="text-xs">Dashboard</span>
      </router-link>

      <router-link 
        to="/boardmanager" 
        class="flex flex-col items-center justify-center text-gray-700 hover:text-green-600 transition-colors"
        :class="{ 'text-green-600': $route.path === '/boardmanager' }"
      >
        <span class="material-icons-outlined text-xl mb-1">settings</span>
        <span class="text-xs">Manager</span>
      </router-link>
    </div>
  </nav>
</template>

<script setup>
import { onMounted } from 'vue';
import { auth } from './auth/auth';

onMounted(() => {
  auth.handleRedirect();
});
</script>

<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

:root {
  --card-height: 400px;
  --card-width: 280px;
}

body {
  @apply antialiased text-gray-800 bg-gray-50;
}
</style>